import Preview from './components/Preview'

const Content = () => {    
  // useEffect(() => {
  //   const addWelcome = () => {
  //     setContext((oldContext: any) => {
  //       return({
  //       ...oldContext, toastList: [{
  //         title: 'Welcome',
  //         description: 'Now go do something great',
  //         backgroundColor: '#5bc0de',
  //         icon: 'info',
  //       }, ...oldContext.toastList],
  //     })})
  //   }
  //   addWelcome()
  // }, [setContext])

  return (<><Preview/></>)
}

export default Content