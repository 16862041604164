import { useState, useEffect, useContext } from 'react'
import { Context } from '../utils/context'
import styled, { keyframes } from 'styled-components'
import { FiX, FiAlertCircle, FiAlertTriangle, FiCheckCircle, FiInfo } from 'react-icons/fi'

type ToastPropType = {
  background?: string
}

type ToastItemType = {
  backgroundColor: string,
  icon: string,
  title: string,
  description: string
}

const Toast = () => {
  const [context, setContext] = useContext(Context)
  const [list, setList] = useState([])

  useEffect(() => {
    setList(context.toastList)
  }, [context.toastList, list])

  useEffect(() => {
    const interval = setInterval(() => {
      const newList = context.toastList.filter((v: any, ii: number) => ii !== 0)
      setContext({ ...context, toastList: newList })
    }, 3000)
    return () => {
      clearInterval(interval);
    }
  })

  const selectIcon = (icon: any) => {
    switch (icon) {
      case 'error': return <FiAlertCircle />
      case 'alert': return <FiAlertTriangle />
      case 'check': return <FiCheckCircle />
      default: return <FiInfo />
    }
  }
  return (
    <ToastContainerStyle>
      {list?.map((item: ToastItemType, i) =>
        <ToastStyle background={item.backgroundColor} key={i}>
          <XStyle onClick={() => {
            const newList = context.toastList.filter((v: any, ii: number) => i !== ii)
            setContext({ ...context, toastList: newList })
          }}
          >
            <FiX />
          </XStyle>
          <ToastOuterStyle>
            <IconStyle>
              {selectIcon(item.icon)}
            </IconStyle>
            <ToastInnerStyle>
              <TitleStyle>
                {item.title}
              </TitleStyle>
              <MessageStyle>
                {item.description}
              </MessageStyle>
            </ToastInnerStyle>
          </ToastOuterStyle>
        </ToastStyle>
      )}
    </ToastContainerStyle>
  )
}

const toastInRight = keyframes`
  from {
    transform: translateX(100%);
    
  }
  to {
    transform: translateX(0);
  }
`

const ToastContainerStyle = styled.div`
  position: fixed;
  z-index: 999998;
  top: 12px;
  right: 12px;
  font-family: 'Source Sans Pro', sans-serif;
  transition: transform .6s ease-in-out;
  animation: ${toastInRight} .7s; 
`

const ToastStyle = styled.div<ToastPropType>`
  font-size: 18px;
  background: ${({ background }) => background ? background : '#fff'};
  transition: .3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 20px;
  margin-bottom: 15px;
  width: 350px;
  max-height: 100px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 0 10px #999;
  color: #fff;
  opacity: .9;
  background-position: 15px;
  background-repeat: no-repeat;
  &:hover {
    box-shadow: 0 0 12px #fff;
	  opacity: 1;
	  cursor: pointer
  }
`

const XStyle = styled.div`
  z-index: 999999;
  font-size: 24px;
  position: relative;
  right: -.3em;
  top: -.3em;
  float: right;
  font-weight: 600;
  outline: none;
  border: none;
  text-shadow: 0 1px 0 #fff;
  opacity: .8;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
`

const ToastOuterStyle = styled.div`
  display: flex;
  flex-direction: row;
`

const IconStyle = styled.div`
  font-size: 36px;
  margin-right: 15px;
`

const ToastInnerStyle = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleStyle = styled.div`
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  width: 300px;
  height: 18px;
`

const MessageStyle = styled.div`
  margin: 0;
  text-align: left;
  margin-left: -1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export default Toast