import { useAuth0 } from '@auth0/auth0-react';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from 'apollo-link-ws';
import { setContext } from '@apollo/client/link/context';

export const GraphQLProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HASURA_HTTPS_URI || ``,
    fetch,
  })

  const wsLink = process.browser ? new WebSocketLink({
    uri: process.env.REACT_APP_HASURA_WSS_URI || ``,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently()
          return {
            headers: {
              Authorization: token ? `Bearer ${token}` : ``,
            },
          }
        }
      },
    },
  }) : null

  const splitLink = process.browser ? split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === `OperationDefinition` &&
        definition.operation === `subscription`
      )
    },
    wsLink,
    httpLink
  ) : httpLink

  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently()
      
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : ``,
        },
      }

    }
  })

  const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
    ssrMode: typeof window === "undefined", // set to true for SSR
    connectToDevTools: true,
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
