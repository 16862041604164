import styled from 'styled-components'

export const PreviewHeader = () => {

  return (
    <MetaHeader>
      <HeaderArea>
        <HeaderTop>
          <HeaderTopPart>
            <a href="https://gransking.fo">
              <SiteLogo src='/granskinglogo2.png' alt='Granskingarráðið' />
            </a>
          </HeaderTopPart>
        </HeaderTop>
        <HeaderLine />
      </HeaderArea>
    </MetaHeader>
  );
};

const MetaHeader = styled.div`
  background-color: #fff;
  width: 100%;
  position: relative;
  top: 0;
  height: calc(65px + 1vw);
`

const HeaderArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: auto;
  padding: 8px 0;
`

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`

const HeaderTopPart = styled.div`
  flex: 1;
`

const SiteLogo = styled.img`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80vw;
  cursor: pointer;
  padding-left: 20px;
  margin: auto;
`

const HeaderLine = styled.div`
  width: 100%;
  height: 15px;
  margin: 15px 0;
  background-color: #58a449;
`