import { useState } from 'react'
import { Context } from './utils/context'
import { Auth0Provider } from '@auth0/auth0-react'
import { GraphQLProvider } from './utils/hasuraProvider'
import { createGlobalStyle } from 'styled-components'
import Main from './Main'

const GlobalStyle = createGlobalStyle`
 body {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   max-width: 100%;
   overflow-x: hidden; 
   font-family: 'Lora', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
   font-style: normal;
   sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
`

function App() {
  const [context, setContext] = useState({
    toastList: [],
    previewPost: null,
    portalBlocks: []
  })  
  return (
    <Context.Provider value={[context, setContext]}>
      <GlobalStyle />
      <Auth0Provider
        domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
        clientId={`${process.env.REACT_APP_AUTH0_CLIENTID}`}
        redirectUri={`${process.env.REACT_APP_REDIRECT_URI}`}
        audience="hasura"
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <GraphQLProvider>
          <Main />
        </GraphQLProvider>
      </Auth0Provider>
    </Context.Provider>
  );
}

export default App
