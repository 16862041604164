import { useEffect, useState, FC } from "react"
import MDEditor from '@uiw/react-md-editor'
import styled from 'styled-components'

interface MarkdownFieldProps {
  initialValue: string,
  updateValue: Function
}

const MarkdownField: FC<MarkdownFieldProps> = ({ initialValue, updateValue }) => {
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    updateValue(value)
  }, [value, updateValue])
  return (
    <MainDiv>
      <MDEditor value={value} onChange={(val: string | undefined) => { if (val !== undefined) { setValue(val) } }} preview="edit" height={400} />
    </MainDiv>
  )
}

const MainDiv = styled.div`
  width: 90%;
  max-width: 90%;
`

export default MarkdownField