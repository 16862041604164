import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'
import Content from './Content'
import Toast from './components/Toast'
import Control from './Control'

type DivPropType = {
  leftAreaWidth: string
}

const Main = () => {
  const [leftAreaWidth, setLeftAreaWidth] = useState('50vw')
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const changeLeftAreaWidth = (clientX: number) => {
    setLeftAreaWidth((clientX > 120 ? clientX : 120) + 'px')
  }
  return (
    <>
      {!isAuthenticated &&
        <PublicDiv>
          <PublicHeadline>Umsókn Admin</PublicHeadline>
          <LoginLogoutButton onClick={() => loginWithRedirect()}>
            Login
          </LoginLogoutButton>
        </PublicDiv>
      }
      {isAuthenticated &&
        <MainDiv>
          <Toast />
          <LeftArea leftAreaWidth={leftAreaWidth}>
            <div>Umsókn Admin</div>
            <Control leftAreaWidth={leftAreaWidth} />
            <LoginLogoutButton onClick={() => logout({ returnTo: window.location.origin })}>
              Logout
            </LoginLogoutButton>
          </LeftArea>
          <VerticalDivider draggable onDragEnd={e => {
            e.preventDefault()
            changeLeftAreaWidth(e.clientX)
          }} />
          <RightArea leftAreaWidth={leftAreaWidth}>
            <Content />
          </RightArea>
        </MainDiv>
      }
    </>
  )
}

const PublicDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PublicHeadline = styled.div`
  font-size: 2em;
  padding: 1em 0;
`

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
`

const LeftArea = styled.div<DivPropType>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: ${({ leftAreaWidth }) => `${leftAreaWidth}`};
  min-height: 100%;
  color: black;
  background-color: #f2f2f2;
  font-size: 20px;
`

const VerticalDivider = styled.div`
  z-index: 999;
  margin: 0;
  padding: 0;
  width: 1px;
  height: 100vh;
  border-right: 2px dotted #2e4f4f;
  color: black;
  background-color: transparent;
  cursor: col-resize;
`

const RightArea = styled.div<DivPropType>`
  margin: 0;
  padding: 0;
  width: ${({ leftAreaWidth }) => `calc(100vw - ${leftAreaWidth})`};
  height: 100%;
  color: black;
  background-color: white;
  font-size: 20px;
`

const LoginLogoutButton = styled.div`
  padding: 10px 20px;
  background-color: darkgray;
  border-radius: 4px;
  cursor: pointer;
`

export default Main
