import { useContext, FC } from 'react'
import { Context } from '../utils/context'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { PreviewHeader } from './PreviewHeader'

const Preview:FC = () => {
  const [context, ] = useContext(Context)

  return (
    <PreviewWrapper>
      <PreviewHeader />
      <BigBody>
        {context.portalBlocks.map((block: any, i: number) => <BodyContainer key={i}>
            <ReactMarkdown children={block.markdownBody} remarkPlugins={[remarkGfm]} components={{
              table: ({ node, ...props }) => <TableStyle {...props} />,
              thead: ({ node, ...props }) => <TableHeadStyle {...props} />,
              // th: ({ node, ...props }) => <TableHeadCellStyle {...props} />,
              // td: ({ node, ...props }) => <TableBodyCellStyle {...props} />
            }} />
          </BodyContainer>)
        }
      </BigBody>
    </PreviewWrapper>
  );
}

const PreviewWrapper = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100vh;
  background-color: #ecedee;
`

const BigBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 35px;
  margin: calc(50px + 1vw) auto 0 auto;
  font-family: Roboto Condensed, sans-serif;
`

const BodyContainer = styled.div`
  min-height: 450px;
  width: 500px;
  box-shadow: 0 -1px 0 #e4e4e4, 0 0 2px rgb(0 0 0 / 12%), 0 2px 4px rgb(0 0 0 / 24%);
  background-color: white;
  margin: 10px;
  padding: 20px;
  font-size: 18px;
  font-weight: 400;
  a {
    color: black;
  }
  a:visited {
    color: gray;
  }
`

const TableStyle = styled.table`
  width: 100%;
`

const TableHeadStyle = styled.thead`
  color: black;
`

// const TableHeadCellStyle = styled.th`
//   font-weight: normal;
//   &:nth-child(1) {
//     width: 50%;
//   }
//   &:nth-child(2) {
//     width: 25%;
//   }
//   &:nth-child(3) {
//     width: 25%;
// `

// const TableBodyCellStyle = styled.td`
//   font-weight: 400;
// `

export default Preview