import { useContext, FC } from 'react'
import { Context } from '../utils/context'
import { gql, useMutation } from '@apollo/client'

interface SubmitPostToHasuraPropTypes {
  makeEditPortalVisible: Function,
  shortname: string,
  values: any
}

const SubmitPostToHasura:FC<SubmitPostToHasuraPropTypes> = ({ makeEditPortalVisible, shortname, values }) => {
  const [updatePortal] = useMutation(UPDATE_PORTAL)
  const [context, setContext] = useContext(Context)
  const { blocks } = values

  return <div onClick={async () => {
    await updatePortal({
      variables: {
        shortname: shortname,
        blocks: JSON.stringify(blocks),
      }
    })
    makeEditPortalVisible(false)
    await setContext({
      ...context, toastList: [{
        title: 'OK',
        description: 'Portal updated. Refresh public page.',
        backgroundColor: '#5cb85c',
        icon: 'check',
      }, ...context.toastList],
      previewPost: null
    })    
    return true
  }
  }>Update</div>
}

const UPDATE_PORTAL = gql`
  mutation UpdatePortal(
    $shortname: String!, $blocks: String!) {
    update_u_portal(where: {
      shortname: {_eq: $shortname}
    }, _set: {
      blocks: $blocks,
    }) {
      affected_rows
    }
  }
`

export default SubmitPostToHasura