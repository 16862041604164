import { useContext, useState, FC } from 'react'
import { Context } from './utils/context'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'
import { FaEdit } from 'react-icons/fa'
import { FiLogOut } from 'react-icons/fi'
import EditPortal from './EditPortal'

type OpenPropType = {
  leftAreaWidth: string
}

const Control:FC<OpenPropType> = ({leftAreaWidth}) => {
  const [editPortalVisible, setEditPortalVisible] = useState(false)
  const [context, setContext] = useContext(Context)
  const makeEditPortalVisible = (newState: boolean) => {
    setEditPortalVisible(newState)
    setContext({ ...context, previewPost: newState ? { title: '' } : null})
  }
  const { logout } = useAuth0()
  return (
    <MenuArea leftAreaWidth={leftAreaWidth}>
      {!editPortalVisible && <UpperMenuArea>
        <MenuItem>
          <MenuItemHeader onClick={() => {
            setContext({ ...context, previewPost: { title: 'gransking' } })
            setEditPortalVisible(true)
          }}>
            <MenuItemIcon><FaEdit /> </MenuItemIcon>
            <MenuItemText>Edit portal content</MenuItemText>
          </MenuItemHeader>
        </MenuItem>
      </UpperMenuArea>}
      {editPortalVisible && <EditPortal makeEditPortalVisible={makeEditPortalVisible} shortname='gransking' />}
      {!editPortalVisible && <LowerMenuArea>
        <MenuItem onClick={() => logout({ returnTo: `${process.env.NEXT_PUBLIC_REDIRECT_URI}` })}>
          <MenuItemHeader>
            <MenuItemIcon><FiLogOut /> </MenuItemIcon>
            <MenuItemText>Útrita</MenuItemText>
          </MenuItemHeader>
        </MenuItem>
      </LowerMenuArea>}
    </MenuArea>
  )
}

const MenuArea = styled.div<OpenPropType>`
  margin: 0;
  padding: 20px 10px 20px 20px;
  height: 100vh;
  width: ${({ leftAreaWidth }) => `${leftAreaWidth}`};
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
`

const UpperMenuArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const LowerMenuArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
`

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const MenuItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  padding-bottom: 10px;
`

const MenuItemIcon = styled.div`
  padding-right: 10px;
  flex-shrink: 3;
  font-size: 26px;
  margin: auto 0;
`

const MenuItemText = styled.div`
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
`

export default Control