import { useEffect, useState, useContext, FC } from 'react'
import { Context } from './utils/context'
import { request } from 'graphql-request'
import styled from 'styled-components'
import { BiArrowBack } from 'react-icons/bi'
import UpdatePostInHasura from './utils/updatepostinhasura'
import MarkdownField from './components/MarkdownField'

interface MarkdownFieldProps {
  makeEditPortalVisible: Function,
  shortname: string
}

const EditPortal: FC<MarkdownFieldProps> = ({ makeEditPortalVisible, shortname }) => {
  const [context, setContext] = useContext(Context)
  const [values, setValues] = useState({
    blocks: [] as any
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      const props: any = await getPortal(shortname)
      setValues({ blocks: JSON.parse(props.props.u_portal[0].blocks) })
      setLoading(false)
    })()
  }, [setValues, setLoading, shortname])

  const setBlock = (blockId: number, newValue: string) => {
    const newBlocks = values.blocks
    newBlocks[blockId] = { _template: "markdown", markdownBody: newValue }
    setValues({ blocks: newBlocks })
    setContext({
      ...context, portalBlocks: newBlocks
    })
    return true
  }

  return <MainDiv>
    <HeaderStyle onClick={() => makeEditPortalVisible(false)}><NavigationIcon><BiArrowBack /></NavigationIcon>{" Edit Portal"}</HeaderStyle>
    {loading ? 'Loading...' : <>
      <LabelStyle>
        First box
      </LabelStyle>
      <MarkdownField initialValue={values.blocks[0].markdownBody} updateValue={(newValue: string) => setBlock(0, newValue)} />
      <br />
      <LabelStyle>
        Second box
      </LabelStyle>
      <MarkdownField initialValue={values.blocks[1].markdownBody} updateValue={(newValue: string) => setBlock(1, newValue)} />
      <ButtonArea>
        <SubmitStyle>
          <UpdatePostInHasura makeEditPortalVisible={makeEditPortalVisible}
            shortname={shortname}
            values={values} />
        </SubmitStyle>
        <CancelStyle onClick={() => {
          makeEditPortalVisible(false)
          setContext({
            ...context, toastList: [{
              title: 'Cancelled',
              description: 'No changes done',
              backgroundColor: '#5bc0de',
              icon: 'info',
            }, ...context.toastList],
            previewPost: null,
          })
        }}>
          Cancel
        </CancelStyle>
      </ButtonArea>
    </>}
  </MainDiv>
}

const getPortal = async (shortname: string) => {
  const pageResult = await request(
    process.env.REACT_APP_HASURA_HTTPS_URI || ``, `
      query GetPortal($shortname: String!){
        u_portal(where: {shortname: {_eq: $shortname}}) {
          id
          blocks
        }
      }
    `,
    { shortname: `${shortname}` }
  )
  return {
    props: {
      ...pageResult
    },
  };
};

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  max-height: 100%;
`

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 18px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 400;
  max-width: 95%;
  padding-bottom: 20px;
  cursor: pointer;  
`

const NavigationIcon = styled.div`
  transform: translateY(3px);
  padding-right: 5px;
`

const LabelStyle = styled.label`
  font-size: 18px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 400;
  min-width: 100px;
  width: 95%;
  max-width: 95%;
`

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const SubmitStyle = styled.div`
  margin: 1rem 20px 1rem 0;
  padding: 0.8rem 1rem;
  background-color: #2e4f4f;
  border: 1px solid #F0F0F0;
  color: white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0.3rem;
`

const CancelStyle = styled.div`
  margin: 1rem 0;
  padding: 0.8rem 1rem;
  background-color: #ddd;
  border: 1px solid #F0F0F0;
  color: #2e4f4f;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0.3rem;
`

export default EditPortal